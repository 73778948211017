import React from "react";
import siteImages from "./../data/SiteImages"
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";

function Header({ pages, onSelectPage }){

  // const {id, title, URL } = pages;

	const headerBg = {
		backgroundImage:
			"url('/images/site/logo-placeholder.png')",
    alt: "placeholder",
		backgroundPosition: "bottom",
		backgroundSize: "100% auto",
	};

  const bgOverlay = {
		backgroundColor: "#FFFFFFA4",
	};

  const logoBg = {
		height: "100%",
		borderRadius: 50,
		boxShadow: "0 0 30px #FFFFFFF6",
		backgroundColor: "#FFFFFF65",
	};

  return (
    <>
    {/* logo */}
    <div  className="headerLogoContainer" style={headerBg}>
      <div className="py-2" style={bgOverlay}>
        <div className="container d-flex justify-content-between align-items-center">
        <div style={logoBg}>
          <a href="/">
            <img 
            src={siteImages[0].image_url}
            alt="placeholder"
            className="HeaderLogo"
            />
          </a>
        </div>
        {/* possible are for email sign up, can be made into fragment */}
        <div className="container">
          <div className="d-flex justify-content-between flex-wrap align-items-center">
            <div className="d-flex flex-wrap align-items-center">
              <p className="fs-6 text-capitalize pe-2 m-0">Sign up for exclusive offers</p>
              {/* email form goes here */}
              {/* create fragment for reusable email form */}
            </div>
          </div>
        </div>

        </div>
      </div>
    </div>
    {/* nav bar toggler button */}
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark py-0">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          {/* <img src={siteImages[0].image_url} width="auto" height="10px" alt="test" /> */}
        </a>
        <button class="navbar-toggler " 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#navbarNavDropdown" 
                aria-controls="navbarNavDropdown" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="justify-content-center navbar-collapse collapse" id="navbarNavDropdown">
          <ul class="h-100 nav navbar-nav">
            {pages.map(page => 
              <li class="nav-item">
                <Link 
                  key={page.id} 
                  to={`/${page.url}`} 
                  onClick={() => onSelectPage(page.id)} 
                  className="h-100 d-flex justify-content-center align-items-center nav-link">
                  {page.title}
                  </Link>
              </li>
              )}
          </ul>
        
        </div>
      </div>
		</nav>
    </>
  );




}

export default Header;