export default class ExpressService {
  // static method to get pages
  static  getPages() {
    // returns promise
    return fetch(`https://api.reactcms2023.viewmynew.com/page`)
      // validate response
      .then((response) => {
        // if response is ok, return jsonified response
        if (response.status === 200) {
          return response.json();
        } else {
          const errorMsg = `${response.status}`;
          throw new Error(errorMsg);
        }
      })
      .catch((error) => {
        return error;
      })
  }
}