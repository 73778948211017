import React from "react";
import logo from './../assets/img/Ad-Solutions-Logo.jpeg'

// import bootstrap from 'bootstrap' 
// import Button from "react-bootstrap/Button";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



function Footer(props) {
  const Year = new Date().getFullYear();


const bgOverlay = {
  // backgroundColor: "rgba(151, 166, 141, .3)",
  position: 'relative',
  zIndex: 1
};

const socialLogo = {
  height: 60,
  width: 60,
  display: "inline-block",
  padding: 4,
  position: "relative",
  color: "#58595b",
};


return (
  <>
  <div className="bg-info object-center object-cover pointer-events-none" style={{
      backgroundImage:"url(/images/site/logo-placeholder.png)",
      alt:"placeholder",
      layout:"fill",
      objectFit:"cover",
      priority:"true",
      position: 'relative'}}
    >
    <div style={bgOverlay}>
      <div className="h-100 w-100 px-5 pb-2 pt-4">
        <div className="row">
          <div className="col-md-9 col-sm-12 d-flex">
            <div className="row">
              <div className="d-flex col-sm-12 justify-content-center">
                {/* place holder for "menu" or footer links */}
                <h1>Look At These Placeholder Links!</h1>
              </div>
              <div className="col-sm-12 col-md-4" style={{minHeight: '200px', minWidth: '200px'}}>
                <a href="/home">
                  <img src={logo} alt="placeholder" />
                </a>
              </div>
              {/* sub menu, in shays case a list of shop categories */}
              <div className="col-sm-12 col-med-8 d-flex justify-content-center align-items-center">
                <h2>look at this middle space</h2>
              </div>
            </div>
          </div>
{/* right most column */}
          <div className="col-sm-12 col-md-3 d-flex flex-column align-items-end">
            <div className="row">
              <div className="col-sm-12">
              <p className="fs-6 text-capitalize text-end m-0 w-100 text-dark">
                Create An Account to receive exclusive offers
              </p>
              </div>
              <div className="col-sm-12">
                <div className="d-flex">
                  {/* placeholder for email sign up field and button */}
                </div>
              </div>
              <div className="col-sm-12"><br /></div>
              <div className="col-sm-12 d-flex justify-content-end">
                <a className="text-decoration-none text-dark text-capitalize"
                rel="noreferrer"
                target="_blank"
                href="https://goo.gl/maps/nEuCTFu7ySUpUcWK7">
                  10639 SE Fuller Rd
                  <br />
                  Portland, OR 97222
                </a>
              </div>
              <div className="col-sm-12"><br /></div>
                <div className="col-sm-12 d-flex justify-content-end">
                  <a className="text-decoration-none text-dark"
                    href="tel:(503) 653-1155" 
                    target="_blank" 
                    rel="noreferrer">
                      (503) 653-1155
                  </a>
                </div>
                <div className="col-sm-12 d-flex justify-content-end">
                  <a target="_blank" 
                    rel="noreferrer" 
                    className="text-decoration-none text-dark" 
                    href="mailto:orders@nodecms2023.com">
                      orders@nodecms2023.com
                  </a>
                </div>
                <div className="col-sm-12"><br /></div>
                <div className="col-sm-12 d-flex justify-content-end">
                <div style={socialLogo}>
                  <a target="_blank" rel="noreferrer" href="https://www.yelp.com">
                    <i class="fa fa-yelp" size="3x"  aria-hidden="true"/>
                  </a>
                </div>
                <div style={socialLogo}>
                  <a target="_blank" rel="noreferrer" href="https://www.google.com/search?q=mauro+is+cool&rlz=1C5CHFA_enUS996US997&oq=mauro+is+cool&aqs=chrome..69i57j0i22i30j0i390i650l2.1774j0j7&sourceid=chrome&ie=UTF-8">
                    <i  class="fa fa-google" size="3x"  aria-hidden="true"/>
                  </a>
                </div>
                <div style={socialLogo}>
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/nodecms2023/">
                    <i class="fa fa-facebook" size="3x"  aria-hidden="true"/>
                  </a>
                </div>
                <div style={socialLogo}>
                  <a href="https://www.instagram.com/nodecms2023/?hl=en" target="_blank" rel="noreferrer" >
                    <i class="fa fa-instagram" size="3x"  aria-hidden="true"/>
                  </a>
                </div>
                </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
        <div>
          <p className="mx-2">
            ©{Year} Company Placeholder .Inc - All Rights Reserved
          </p>
        </div>
        <div>
          <div className="mx-2">
            <a href="https://www.neturf.com"
              rel="noreferrer"
              target="_blank"
              title="Website Application Powered by: Neturf"
              >
                Powered by: Neturf
            </a>
            |
            <a href="https://www.advertisingsolutions.agency"
              rel="noreferrer"
              target="_blank"
              title="Website Application Managed by: Advertising Solutions"
              >
                Managed by: Ad Sol
            </a>
          </div>
        </div>
        </div>
      </div>
    </div>

  </div>
  </>
  );
}

export default Footer;