import logo from './../assets/img/Ad-Solutions-Logo.jpeg'

const siteImages = [
  {
    id: 1,
    image_url: logo,
    file_path: "./../assets/img/Ad-Solutions-Logo.jpeg",
    name: "This is the Page page",
    alt: "Advertising solutions, for all your advertising solutions",
  }
]

export default siteImages;