import React from "react";

const ContentControl = ({ page }) => {
  const {title, short_description, description, content} = page;

  return(
    <React.Fragment>
      <h1>{title}</h1>
      <h2>{short_description}</h2>
      <p>{description}</p>
      <p>{content}</p>
    </React.Fragment>
  );
}

export default ContentControl;