import React, { useEffect, useState} from 'react';
import ExpressService from './../services/express-service';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContentControl from "./ContentControl"
import Footer from "./Footer"
import Header from "./Header"
import './../assets/css/App.css';

function App() {
  const [pages, setPages] = useState([]);
  const [pagesError, setPagesError] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedPath, setSelectedPath] = useState(null);

  // fetch pages with service
  useEffect(() => { 
    ExpressService.getPages()
      .then((pagesData) => {
        // check if error
        if (pagesData instanceof Error) {
          setPagesError(pagesData.message);
          throw new Error(pagesData);
        }
        // add pages to state
        setPages(pagesData);

      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  // function take user input from Header element, set selected page to pass to content control
  const setPage = (id) => {
    const pageSelected = pages.filter(page => page.id === id)[0];
    setSelectedPage(pageSelected)
    setSelectedPath(`/${pageSelected.url}`)
  }

  // conditional rendering for selected page
  let routeContent = null
  if (selectedPage) {
    routeContent = <Route path={selectedPath} element={<ContentControl page={selectedPage}/>} />
  }

  // set default content 
  let defaultPage = null;
  // if API call successful
  if (pages) {
    // displays at route '/' if API call succeeds
    defaultPage = {
      title: "Welcome",
    }
  } else {
    // displays at route '/' if API call fails
    defaultPage = {
      title: "Loading",
    }
  }

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Header pages={pages} onSelectPage={setPage}/>
        </header>
          <p>{pagesError}</p>
          <Routes> 
            {routeContent}
            {pages.map(page =>
              <Route key={page.id}  path={`/${page.url}`} element={<ContentControl page={page}/>} />
              )}
            <Route path='/' element={<ContentControl page={defaultPage}/>} />
          </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
